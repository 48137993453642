import { Link } from "react-router-dom";
import './Heading.scss';

function Heading() {
    return <header>
        <div>
            <h1><Link to="/">Cool Blog</Link></h1>
            <nav><Link to="/about">About</Link></nav>
        </div>
    </header>
}

export default Heading;