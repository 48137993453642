import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from "../data/Api";
import NavContext from "../contexts/NavContext";
import './Sidebar.scss';

function Sidebar() {
    const context = useContext(NavContext);

    useEffect(() => {
        API.getRecent().then(obj => {
            context.setPageData({
                type: "SET_SIDEBAR",
                data: obj
            });
        })
    }, [1]);

    return <div className="Sidebar">
        <h2>Sidebar</h2>
        {context.pageData.sidebar.map((post) => <div key={post._id} >
            {post.mainImage &&
                <img src={post.mainImage.asset.url + '?w=200'} />
            }
            <h3><Link to={'/post/' + post.slug.current}>{post.title}</Link></h3>
            <p className="published">{post.publishedAt}</p>
        </div>
        )}
    </div>;
}

export default Sidebar;