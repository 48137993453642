import sanityClient from './Client';

class API_Wrapper {
  static dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  getPost(post_slug: string) {
    return sanityClient
      .fetch(`*[_type == "post" && slug.current == "${post_slug}" ] {
        _id,
        title,
        body[]{
          ...,
          asset{
            ...,
            "url":@->url
          }
        },
        publishedAt
      } `)
      .then(data => {
        const post = data[0];
        if (!post) throw new Error('Post not found');

        const time = new Date(post.publishedAt);

        post.publishedAt = {
          datetime: time.toISOString(),
          formatted: time.toLocaleDateString("en-US", API_Wrapper.dateOptions),
        }

        return post;
      });
  }

  getRecent() {
    return sanityClient
      .fetch(
        `*[_type == "post"]{
              _id,
              title,
              slug,
              publishedAt,
              mainImage{
                asset->{
                _id,
                url
              }
            }
          } | order(publishedAt desc)`
      )
      .then((data) => {
        data.map((post) => {
          post.publishedAt = new Date(post.publishedAt)
            .toLocaleDateString("en-US", API_Wrapper.dateOptions);
          return post;
        });

        return data;
      })
      .catch(console.error);
  }
}

const API = new API_Wrapper();
export default API;