import Sidebar from "../components/Sidebar";
import './Index.scss';

function Index() {
    return <>
        <Sidebar />
        <main>
            <h1>Index</h1>
            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel erat ex. Etiam commodo nisl justo, et rutrum ipsum fermentum ac. Fusce elementum odio ut vestibulum malesuada. Quisque rhoncus nibh at lacus sagittis malesuada. Vestibulum pharetra sem metus, ac eleifend tortor pellentesque nec. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin ac nisl fermentum, elementum lectus eget, rutrum mauris. Suspendisse vel mi vitae velit ultrices dictum. Nulla vitae urna in lacus tempus elementum. Donec luctus purus et lectus blandit ultricies ut vel nibh. Sed at elit risus. Morbi et egestas urna, vel fermentum justo. Sed non aliquet libero. Praesent et diam et felis elementum sodales pulvinar a neque. Nulla tortor sapien, varius sed dolor eu, aliquet posuere nibh. </p>

            <p>Morbi tristique massa odio, vitae venenatis ligula iaculis quis. Duis hendrerit tellus eget odio vulputate, non pharetra lacus finibus. Maecenas pretium tellus id mauris malesuada, vel egestas diam eleifend. In dapibus varius neque, sed finibus risus rutrum non. Nam dapibus iaculis finibus. Mauris congue justo nec nulla placerat, eget consectetur elit luctus. Duis eleifend tempus nulla. Cras iaculis lorem urna, non iaculis ipsum luctus id. Donec a neque imperdiet leo convallis facilisis. Nunc egestas urna tortor, ut tempus lectus scelerisque ac. Suspendisse justo quam, pretium aliquet bibendum pretium, suscipit eget dolor. </p>

            <p>Integer sed libero a velit aliquam lobortis. In hac habitasse platea dictumst. Duis rhoncus egestas neque. Integer eros nisl, egestas id fermentum in, iaculis sit amet nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed risus ac leo hendrerit venenatis non commodo massa. Aliquam eget turpis turpis. Vivamus mollis egestas aliquam. Nam cursus, metus et congue mattis, orci lectus faucibus justo, eu pretium urna purus nec arcu. Donec tincidunt odio vel erat volutpat rutrum. Sed mollis, ex sit amet pellentesque auctor, est diam lobortis mauris, et euismod ligula justo a sapien. Integer ut eros finibus, cursus nisl vitae, gravida metus. Nunc eros velit, sagittis vitae sem nec, accumsan sodales nibh. </p>
        </main>
    </>;
}

export default Index;