function NavReducer(state, action) {
    switch (action.type) {
        case 'SET_POST':
            return {...state, post: action.data}

        case 'SET_SIDEBAR':
            return { ...state, sidebar: action.data };

        default:
            console.log('NavReducer action', action);
            break;
    }

    return state;
}

export default NavReducer;