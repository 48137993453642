import { Routes, Route, BrowserRouter } from "react-router-dom";
import About from './pages/About';
import Index from './pages/Index';
import Post from './pages/Post';
import NotFound from './pages/NotFound';

import Heading from "./components/Heading";

function Routing(props) {
    return <BrowserRouter>
        <Heading />
        <div className="App">
            <Routes location={props.location}>
                <Route
                    exact
                    path='/about'
                    element={<About />}
                />
                <Route
                    path='/post/:slug'
                    element={<Post />} />
                <Route
                    path="/"
                    element={<Index />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </div>
        <footer>2022</footer>
    </BrowserRouter>;
}

export default Routing;