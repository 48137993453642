import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";

import API from "../data/Api";
import Sidebar from "../components/Sidebar";
import NavContext from "../contexts/NavContext";
import ImageSerializer from "../data/ImageSerializer";

import './Post.scss';

function Post() {
    const context = useContext(NavContext);
    const params = useParams();

    useEffect(() => {
        API.getPost(params.slug).then(obj => {
            document.title = obj.title;
            context.setPageData({
                type: "SET_POST",
                data: obj
            });
        }).catch((e) => {
            console.log('API.getPost error', e);

            context.setPageData({
                type: "SET_POST",
                data: false
            });
        });
    }, [params.slug]);

    return <>
        <Sidebar />
        <main>
            {!context.pageData.post &&
                <article>
                    <h1>Not found</h1>
                </article>
            }
            {context.pageData.post._id &&
                <article key={context.pageData.post._id} className="Post">
                    <h1>{context.pageData.post.title}</h1>
                    <p className="published">
                        Posted:
                        <time dateTime={context.pageData.post.publishedAt.datetime}>{context.pageData.post.publishedAt.formatted}</time>
                    </p>
                    <BlockContent
                        blocks={context.pageData.post.body}
                        imageOptions={{
                            w: 320, h: 240, fit: 'max',
                        }}
                        serializers={{
                            types: {
                                ...ImageSerializer
                            }
                        }}
                    />
                </article>
            }
        </main>
    </>;

}

export default Post;