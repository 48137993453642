import { createContext } from 'react';

interface PageDataInterface {
    post: {
        _id?: "string",
        title?: "string",
        publishedAt?: any,
        body?: any
    },
    sidebar: any
}

interface NavContextInterface {
    pageData: PageDataInterface,
    setPageData: (data: object) => void,
}

const NavContext = createContext<NavContextInterface>({
    pageData: {
        post: {},
        sidebar: [],
    },
    setPageData: () => { },
});

export default NavContext;