import React, { useReducer } from "react";
import Routing from "./Routing";
import NavContext from "./contexts/NavContext";
import NavReducer from "./reducers/NavReducer";
import './App.scss';

function App() {
  const [pageData, setPageData] = useReducer(NavReducer, {
    post: {},
    sidebar: []
  });

  return (
    <NavContext.Provider value={{ pageData, setPageData }}>
      <Routing />
    </NavContext.Provider>
  );
}

export default App;
