import { ReactElement } from "react";

interface PropsInterface {
    options: any,
    node: any
}

/**
 * add alt to images
 */
const ImageSerializer = {
    image: function (props: PropsInterface): ReactElement {
        const qs = new URLSearchParams(props.options.imageOptions);
        const img = <img
            src={`${props.node.asset.url}?${qs.toString()}`}
            alt={props.node.alt}
        />;

        return img;
    }
}

export default ImageSerializer;