function About() {
    return <main><h1>About</h1>
        <p> Donec in dolor eu massa volutpat consectetur vitae sed nisi. Praesent vestibulum in tortor at accumsan. Phasellus sit amet purus egestas lorem faucibus molestie. Curabitur quis eros bibendum, commodo quam vel, volutpat sem. Vivamus ut pretium mi. Nulla et sapien ut lectus dictum porta. Maecenas pretium mauris sed ex scelerisque, id tempor tellus vestibulum. Nam a consectetur velit, ac pretium ante. Pellentesque at rutrum massa. Donec bibendum tempus elit eget congue.</p>

        <p> Vivamus sit amet nisi vitae velit pharetra bibendum. Phasellus nec hendrerit mi. Morbi eu interdum turpis. Duis venenatis elementum odio, id sagittis orci bibendum tincidunt. Fusce sit amet tortor iaculis, facilisis nulla a, ornare nisl. Proin sagittis orci sit amet ex suscipit, sit amet sodales purus volutpat. In suscipit tempor nulla ut pharetra. In pulvinar congue ipsum, vel sagittis eros tempor et. In hac habitasse platea dictumst. Nulla eu lectus mollis enim lacinia venenatis. Sed purus quam, consectetur eu tortor a, scelerisque dignissim purus. In diam velit, gravida a justo vitae, condimentum euismod leo. In ultricies aliquam neque, id lobortis odio convallis at. Etiam luctus libero id mi rhoncus fringilla.</p>

        <p>Vestibulum ut libero in dui efficitur venenatis. Donec vel fringilla magna. Mauris euismod ante nulla, quis dignissim urna sollicitudin at. Ut hendrerit sem sed augue hendrerit, eu aliquet mauris lobortis. Morbi vehicula elit nec condimentum ullamcorper. Duis sit amet nisl non massa aliquam consectetur quis eu sapien. Sed at enim eleifend, tincidunt dui ac, auctor augue. Integer imperdiet consectetur erat, in sollicitudin velit hendrerit eu. Duis imperdiet leo ac libero consectetur, quis dapibus massa elementum. Ut id massa fringilla, porta elit vitae, rhoncus libero. Etiam porta porttitor lectus, sed pulvinar urna. Nulla neque eros, faucibus a libero at, laoreet dignissim odio. Nunc suscipit lacus id </p>
    </main>;
}

export default About;